import CentredLoader from "./CentredLoader";
import {
  Card,
  CardActionArea,
  CardContent,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { numberToAlphabetical } from "common/numbers";
import * as config from "config";
import { CLAIMS_MAPPING_IGNORE_VALUE, EXCLUDE_KEY } from "config";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useDoConversionQuery,
  useGetClaimsMappingQuery,
  useGetConversionErrorsQuery,
  useGetHeaderRowQuery,
} from "services/tablesService";
import { setV2ClaimsKey } from "store/actions/input/claims/claimsActions";

const ErrorsTable = ({ errors, colOffset, headers }) => {
  return (
    <Paper elevation={0}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{"Column"}</TableCell>
              <TableCell>{"Cell"}</TableCell>
              <TableCell>{"Spreadsheet Value"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errors.map((error) => (
              <TableRow>
                <TableCell>{(headers ?? [])[error.column]}</TableCell>
                <TableCell>{`${numberToAlphabetical(error.column + colOffset)}${
                  error.row + 1
                }`}</TableCell>
                <TableCell>{error.failingValue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export const ClaimsTransformer = ({
  fileId,
  origin,
  tableName,
  headerRows,
  mapping,
  filename,
  tablesList,
  onConversionComplete,
}) => {
  const [columnMappings, setColumnMappings] = useState({});
  const [progressMessage, setProgressMessage] = useState("");

  useEffect(() => {
    const colMap = {};
    if (mapping) {
      for (const index in mapping) {
        const entry = mapping[index];
        colMap[entry.outputName] = entry.inputColumn;
      }
      setColumnMappings(colMap);
    }
  }, [mapping, setColumnMappings]);

  const { data: headings } = useGetHeaderRowQuery(
    {
      fileId,
      tableName,
      skipRows: origin.row,
      skipColumns: origin.column,
      headerRows,
    },
    { skip: !fileId || !tableName }
  );
  const substitutions = [];
  const rowDeletions = [];
  const [deletionMappings, setDeletionMappings] = useState([]);
  const [openClosedMapping, setOpenClosedMapping] = useState(null);
  const [coverageMapping, setCoverageMapping] = useState(null);
  const [coverageDefault, setCoverageDefault] = useState(null);
  const [activeErrorCode, setActiveErrorCode] = useState(null);

  useEffect(() => {
    if (Object.keys(columnMappings ?? {}).length > 0) {
      if (EXCLUDE_KEY in columnMappings) {
        const excludeColumn = columnMappings[EXCLUDE_KEY];
        const excMapping = (
          mapping?.find((me) => me.outputName === EXCLUDE_KEY)?.conversions ??
          []
        ).find((e) => "IN_DELETE_ROW" === e.conversionType)?.conversions;
        if (Object.keys(excMapping ?? {}).length === 0) return;
        const excVals = Object.entries(excMapping)
          .filter(([_, func]) => func === CLAIMS_MAPPING_IGNORE_VALUE)
          .map(([val, _]) => val);
        if (excVals.length > 0) {
          const delMap = [{ column: excludeColumn, values: excVals }];
          if (JSON.stringify(delMap) !== JSON.stringify(deletionMappings)) {
            setDeletionMappings(delMap);
          }
        }
      }
    }
  }, [columnMappings, setDeletionMappings, deletionMappings, mapping]);

  useEffect(() => {
    const generateMappingDataForEnumCol = (
      colName,
      mappingSetter,
      existing
    ) => {
      const getMapping = (name) => {
        const entry = mapping?.filter((me) => me.outputName === name);
        return (entry?.[0]?.conversions ?? []).filter(
          (e) => e.conversionType === "CONVERT"
        )?.[0]?.conversions;
      };
      const enMapping = getMapping(colName);
      if (Object.keys(enMapping ?? {}).length === 0) return;
      const enFilter = Object.fromEntries(
        Object.entries(enMapping).filter(
          ([_, mapped]) => mapped !== config.CLAIMS_MAPPING_IGNORE_VALUE
        )
      );
      if (JSON.stringify(enFilter) !== JSON.stringify(existing ?? {})) {
        mappingSetter(enFilter);
      }
    };

    generateMappingDataForEnumCol(
      "_OPEN_CLOSED",
      setOpenClosedMapping,
      openClosedMapping
    );

    const coverageConversion =
      mapping.find((x) => x.outputName === "_COVERAGE")?.conversions?.[0] ?? {};

    if (coverageConversion.conversionType === "CONSTANT") {
      setCoverageDefault(coverageConversion.value);
    } else {
      generateMappingDataForEnumCol(
        "_COVERAGE",
        setCoverageMapping,
        coverageMapping
      );
    }
  }, [
    mapping,
    setOpenClosedMapping,
    setCoverageMapping,
    coverageMapping,
    openClosedMapping,
  ]);

  const hasCoverage =
    Object.keys(coverageMapping ?? {}).length > 0 || coverageDefault != null;

  const {
    data: mappingTable,
    status: mappingTableStatus,
  } = useGetClaimsMappingQuery(
    {
      fileId,
      columnMappings,
      substitutions,
      rowDeletions,
      deletionMappings,
      openClosedMapping,
      openClosedDeleteUnmappedRows: true,
      coverageMapping: coverageMapping ?? [],
      coverageDeleteUnmappedRows: true,
      coverageDefault: coverageDefault ?? "GL",
      excess: 0,
      threshold: 0,
    },
    {
      skip: !hasCoverage || !fileId,
    }
  );

  const {
    data: conversionErrors,
    status: conversionErrorsStatus,
  } = useGetConversionErrorsQuery(
    {
      fileId,
      tableName,
      skipColumns: origin.column,
      skipRows: origin.row,
      headerRows,
      skipBlankRows: true,
      mapping: mappingTable,
    },
    { skip: mappingTableStatus !== "fulfilled" || !fileId || !tableName }
  );

  const hasConversionErrors =
    conversionErrorsStatus === "fulfilled" && conversionErrors?.errors?.length;

  const {
    data: processingResponse,
    status: processingResponseStatus,
  } = useDoConversionQuery(
    {
      fileId,
      tableName,
      skipColumns: origin.column,
      skipRows: origin.row,
      headerRows,
      skipBlankRows: true,
      mapping: mappingTable,
      progressNotification: (timing) => {
        if (timing?.rowsProcessed) {
          setProgressMessage(
            `${timing.rowsProcessed} rows processed ` +
              `${timing.percentageComplete}% estimated ` +
              `${timing.remainingSeconds} seconds left`
          );
        }
      },
    },
    {
      skip:
        mappingTableStatus !== "fulfilled" ||
        hasConversionErrors ||
        !fileId ||
        !tableName,
    }
  );

  const dispatch = useDispatch();
  const [createdFile, setCreatedFile] = useState(null);
  useEffect(() => {
    if (
      processingResponseStatus === "fulfilled" &&
      processingResponse.createdFile &&
      createdFile !== processingResponse.createdFile
    ) {
      setCreatedFile(processingResponse.createdFile);
      dispatch(
        setV2ClaimsKey(
          processingResponse.createdFile,
          fileId,
          tableName,
          filename,
          tablesList
        )
      );
      onConversionComplete();
    }
  }, [
    createdFile,
    setCreatedFile,
    processingResponse,
    processingResponseStatus,
    dispatch,
    fileId,
    tableName,
    filename,
    tablesList,
    onConversionComplete,
  ]);

  const errorStats = (conversionErrors?.errors ?? [])
    .filter((x) => x.outputColumn !== "_TOTAL_LOSS")
    .reduce((acc, cur) => {
      if (acc[cur.errorCode] == null) {
        acc[cur.errorCode] = {
          code: cur.errorCode,
          label: cur.error,
          count: 0,
        };
      }
      acc[cur.errorCode].count += 1;
      return acc;
    }, {});

  const activeOrFirstErrorCode =
    activeErrorCode ?? conversionErrors?.errors?.[0]?.errorCode;

  const activeErrors = (conversionErrors?.errors ?? [])
    .filter(
      (x) =>
        x.errorCode === activeOrFirstErrorCode &&
        x.outputColumn !== "_TOTAL_LOSS"
    )
    .slice(0, 50);

  if (conversionErrorsStatus !== "fulfilled") {
    return <CentredLoader label={"Validating data " + progressMessage} />;
  } else if (hasConversionErrors) {
    return (
      <Stack direction={"column"} spacing={2}>
        {(conversionErrors?.errors?.length ?? 0) > 0 && (
          <Stack direction={"column"} spacing={1}>
            <Typography>
              {
                "Marmalade encountered the following issues whilst importing the claims data:"
              }
            </Typography>
            <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
              {Object.values(errorStats).map((error, index) => {
                return (
                  <Card
                    variant={"outlined"}
                    key={index}
                    className={
                      error.code === activeOrFirstErrorCode ? "active" : null
                    }
                    sx={{
                      width: "33%",
                      maxWidth: "14rem",
                      "&.active": {
                        borderColor: "#fa7b35",
                        background: "#fff1e9",
                      },
                    }}
                  >
                    <CardActionArea
                      sx={{ height: "100%" }}
                      onClick={() => setActiveErrorCode(error.code)}
                    >
                      <CardContent>
                        <b>
                          {error.count}
                          {error.count === 1 ? " item" : " items"}
                        </b>
                        <Typography>{error.label}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                );
              })}
            </Stack>
            <ErrorsTable
              errors={activeErrors}
              colOffset={origin.column}
              headers={headings?.header}
            />
          </Stack>
        )}
      </Stack>
    );
  } else {
    return <CentredLoader label={"Validating data " + progressMessage} />;
  }
};

export default ClaimsTransformer;
