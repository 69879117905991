import ClaimsDialog from "../ingestion/ClaimsDialog";
import ClaimsAndDeltaChart from "./ClaimsAndDeltaChart";
import ClaimsChart from "./ClaimsChart";
import ClaimsComparisonTable from "./ClaimsComparisonTable";
import ClaimsDeltaChart from "./ClaimsDeltaChart";
import ClaimsDownloader from "./ClaimsDownloader";
import ClaimsFileUploadDialog from "./ClaimsFileUploadDialog";
import ClaimsLargest from "./ClaimsLargest";
import ClaimsMerger from "./ClaimsMerger";
import ClaimsTable from "./ClaimsTable";
import FilteredClaims from "./FilteredClaims";
import ManualClaims from "./ManualClaims";
import SelfInsuredRetentions from "./SelfInsuredRetentions";
import FileUpload from "components/FileUpload";
import Disabled from "components/common/Disabled";
import FeatureFlagged from "components/common/FeatureFlagged";
import Notes from "components/common/Notes";
import Visible from "components/common/Visible";
import * as claims from "domain/claims";
import { tabularMimeTypes } from "fileUtils";
import { CREATE_CLAIMS_COVERAGE_LIST } from "flags";
import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as claimsService from "services/claimsService";
import * as claimsActions from "store/actions/input/claims/claimsActions";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const ClaimsDialog20240916 = () => {
  const dispatch = useDispatch();

  const claims = useSelector((state) => state.input.claims ?? {});

  return (
    <FileUpload
      onDrop={(files) => {
        const file = files[0];
        dispatch(claimsActions.uploadFile(file));
      }}
      updateAsAtDate={(claimsAsAtDate) =>
        dispatch(claimsActions.updateClaimsAsAtDate(claimsAsAtDate))
      }
      asAtDate={claims.asAtDate}
      recommendedAsAtDate={claims.recommendedAsAtDate}
      size={{ md: 4 }}
      mimeTypes={tabularMimeTypes}
    />
  );
};

const SummaryCharts = ({ claimsQuery }) => {
  const { currentData, isFetching, error } = claimsService.useSummaryChartQuery(
    claimsQuery
  );
  return (
    <>
      <ClaimsChart
        title={"Loss Frequency"}
        subtitle={"Number of losses per year"}
        chart={currentData?.frequency}
        loading={isFetching}
        error={error}
        dataName={"Frequency"}
      />
      <ClaimsChart
        title={"Loss Severity"}
        subtitle={"Maximum loss in a year"}
        chart={currentData?.severity}
        dataName={"Severity"}
        loading={isFetching}
        error={error}
        ultimateName={"Development & Inflation"}
      />
      <ClaimsChart
        title={"Loss Aggregate"}
        subtitle={"Total loss per year"}
        chart={currentData?.aggregate}
        loading={isFetching}
        error={error}
        dataName={"Aggregate"}
        ultimateName={"Development & Inflation"}
      />
    </>
  );
};

const AllClaimsChart = ({ claimsQuery }) => {
  const claimsAbove = useSelector(claimsSelectors.selectClaimsThreshold);
  const { currentData, error } = claimsService.useAllClaimsChartQuery({
    claimsQuery,
    claimsAbove,
  });
  return (
    <FilteredClaims
      data={currentData}
      error={error}
      disabled
      zoomIdentifier={"claims"}
    />
  );
};

export const ClaimsComponent = (props) => {
  const inputClaimsQuery = useDebouncedValue(
    useSelector(claimsSelectors.selectInputClaims)
  );

  const hasClaimsToShow = !claims.isClaimsQueryEmpty(inputClaimsQuery);

  const createCoverageList =
    props.componentVisibility?.[CREATE_CLAIMS_COVERAGE_LIST] ?? true;

  const [claimsComparisonQuery, setClaimsComparisonQuery] = useState({
    primaryMatch: "_DATE_OF_LOSS",
    requiredMatchColumns: ["_COVERAGE", "_CLAIMANT_NAME"],
    matchColumns: ["_DESCRIPTION", "_OPEN_CLOSED"],
    textColumns: [
      "_COVERAGE",
      "_CLAIMANT_NAME",
      "_DESCRIPTION",
      "_OPEN_CLOSED",
    ],
    numericColumns: ["_TOTAL_LOSS"],
    limit: 100,
  });

  const [mergingClaims, setMergingClaims] = useState(false);
  const [claimsState, setClaimsState] = useState({});
  const toggleMergingClaims = () => {
    setMergingClaims(!mergingClaims);
    setClaimsState({});
  };
  const stopMerging = () => {
    setMergingClaims(false);
    setClaimsState({});
  };

  const merge = () => {
    const merger = new ClaimsMerger();
    merger.doMerge(
      claimsState,
      props.toggleClaimExclude,
      props.createMergedClaim,
      stopMerging
    );
  };
  const rowToKey = (row) => row.key + ":" + row.index;

  const setMergeStateEntry = (row, selected) => {
    const entry = rowToKey(row);
    const newClaimsState = {
      ...claimsState,
      [entry]: { row, selected },
    };
    setClaimsState(newClaimsState);
  };
  const mergeEntrySelected = (row) => {
    const entry = rowToKey(row);
    if (entry in claimsState) {
      return claimsState[entry].selected;
    }
    return false;
  };
  return (
    <>
      <ClaimsFileUploadDialog />
      {hasClaimsToShow && (
        <>
          <SummaryCharts claimsQuery={inputClaimsQuery} />
          <AllClaimsChart claimsQuery={inputClaimsQuery} />
        </>
      )}
      <Visible
        byTag={"input.claims.selfInsuredRetentions"}
        defaultVisible={false}
      >
        <SelfInsuredRetentions />
      </Visible>
      <Disabled ifReadOnly>
        <FeatureFlagged
          flag={"ui/useClaimsV2Frontend"}
          on={ClaimsDialog}
          off={ClaimsDialog20240916}
        />
      </Disabled>
      <ClaimsDownloader />
      <ManualClaims
        {...{ mergingClaims, toggleMergingClaims, createCoverageList }}
      />
      {hasClaimsToShow && (
        <ClaimsTable
          {...{
            mergingClaims,
            mergeEntrySelected,
            setMergeStateEntry,
            merge,
            createCoverageList,
          }}
        />
      )}
      <Visible
        byTag={"input.claims.claimsAndDeltaChart"}
        defaultVisible={false}
      >
        <ClaimsAndDeltaChart
          query={claimsComparisonQuery}
          setQuery={setClaimsComparisonQuery}
        />
      </Visible>
      <Visible byTag={"input.claims.claimsDeltaChart"} defaultVisible={false}>
        <ClaimsDeltaChart
          query={claimsComparisonQuery}
          setQuery={setClaimsComparisonQuery}
        />
      </Visible>
      <Visible
        byTag={"input.claims.claimsComparisonTable"}
        defaultVisible={false}
      >
        <ClaimsComparisonTable
          query={claimsComparisonQuery}
          setQuery={setClaimsComparisonQuery}
        />
      </Visible>
      <Notes
        md_width={12}
        lg_width={6}
        update={props.updateNote}
        value={props.note}
      />
      {hasClaimsToShow && (
        <Visible byTag={"input.claims.claimsLargest"} defaultVisible={true}>
          <ClaimsLargest />
        </Visible>
      )}
    </>
  );
};

const mapDispatchToProps = {
  toggleClaimExclude: claimsActions.toggleClaimExclude,
  updateNote: claimsActions.updateClaimsNote,
  claimsFileChecked: claimsActions.claimsFileChecked,
  createMergedClaim: claimsActions.createManualClaim,
};

const mapStateToProps = (state) => ({
  ...state.input.claims,
  componentVisibility: userSelectors.selectUserConfig(state)
    .componentVisibility,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsComponent);
