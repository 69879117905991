import { adminApi } from "./adminService";
import { analyticsApi } from "./analyticsService";
import { bffApi } from "./bffService";
import { claimsApi } from "./claimsService";
import { docGenApi } from "./docGenService";
import { emailServiceApi } from "./emailService";
import { exchangeApi } from "./exchangeService";
import { filesApi } from "./filesService";
import { ingestApi } from "./ingestService";
import { reportApi } from "./reportService";
import { sovApi } from "./sovService";
import { staticDataApi } from "./staticDataService";
import { submissionsApi } from "./submissionsService";
import { suggestionsApi } from "./suggestionService";
import { tablesApi } from "./tablesService";
import { transferApi } from "./transferService";
import { userServiceApi } from "./userService";

const allApis = [
  adminApi,
  analyticsApi,
  bffApi,
  claimsApi,
  docGenApi,
  emailServiceApi,
  exchangeApi,
  filesApi,
  tablesApi,
  ingestApi,
  reportApi,
  sovApi,
  staticDataApi,
  submissionsApi,
  transferApi,
  userServiceApi,
  suggestionsApi,
];

export default allApis;
