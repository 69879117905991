import CentredLoader from "./CentredLoader";
import {
  CheckCircle as CompleteIcon,
  RadioButtonUnchecked as IncompleteIcon,
  InfoRounded as InfoIcon,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  Divider,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import * as config from "config";
import { useEffect, useState } from "react";
import { useGetNamedMappingSuggestionsQuery } from "services/suggestionService";
import { useGetColumnSummaryQuery } from "services/tablesService";
import * as utils from "utils";

const ignoreKey = config.CLAIMS_MAPPING_IGNORE_VALUE;
const unmappedKey = "";
const coverageKey = "_COVERAGE";

const Header = ({ label, stats }) => {
  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        minWidth: "26rem",
        padding: "1rem",
        borderTopLeftRadius: "0.375rem",
        borderTopRightRadius: "0.375rem",
        borderBottom: "1px solid gainsboro",
        background:
          stats.total === 0
            ? "#f3f4f7"
            : stats.allMapped
            ? "#effff7"
            : "#ffefee",
      }}
    >
      <Typography variant={"subtitle1"}>{label}</Typography>
      {stats.total > 0 && (
        <Stack direction={"row"} gap={0.5}>
          <Typography variant={"caption"}>
            {stats.mapped}
            {" / "}
            {stats.total}
          </Typography>
          {stats.allMapped ? (
            <CompleteIcon fontSize={"small"} sx={{ color: "seagreen" }} />
          ) : (
            <IncompleteIcon fontSize={"small"} />
          )}
        </Stack>
      )}
    </Stack>
  );
};

const columnSx = {
  border: "1px solid gainsboro",
  borderRadius: "0.375rem",
};

const Column = ({
  fileId,
  tableName,
  origin,
  headerRows,
  controller,
  mapping,
  updateValueMapping,
  setAllValuesMapped,
}) => {
  const [inputColumn, setInputColumn] = useState(null);
  const [values, setValues] = useState([]);
  const [suggestionsMap, setSuggestionsMap] = useState(null);
  const {
    data: valueSummary,
    status: valueSummaryStatus,
  } = useGetColumnSummaryQuery(
    {
      fileId,
      tableName,
      skipColumns: origin?.column ?? 0,
      skipRows: origin?.row ?? 0,
      headerRows,
      column: inputColumn,
    },
    { skip: inputColumn == null || !fileId || !tableName }
  );
  useEffect(() => {
    const outNameEntry = mapping?.filter(
      (me) => me.outputName === controller.key
    );
    if (
      outNameEntry?.length === 1 &&
      (outNameEntry[0].inputColumn ?? -1) >= 0 &&
      outNameEntry?.[0]?.conversions?.[0].conversionType !== "CONSTANT"
    )
      setInputColumn(outNameEntry[0].inputColumn);
  }, [mapping, setInputColumn, controller]);

  const {
    data: suggestions,
    status: suggestionsStatus,
  } = useGetNamedMappingSuggestionsQuery(
    {
      name:
        controller.key === "_OPEN_CLOSED"
          ? "openClosedMapping"
          : "lossTypeMapping",
      values,
    },
    { skip: !values || !utils.isEmptyObject(suggestionsMap) }
  );

  useEffect(() => {
    if (suggestionsStatus === "fulfilled") {
      const sugmap = suggestions?.suggestions ?? {};
      setSuggestionsMap(
        Object.fromEntries(
          Object.entries(sugmap).map(([k, l]) => [k, l[0]?.suggestion])
        )
      );
    }
  }, [suggestions, suggestionsStatus]);

  useEffect(() => {
    if (valueSummaryStatus === "fulfilled") {
      setValues(
        Object.entries(valueSummary.values).map(([key, { count }]) => ({
          key,
          count,
          mapping: null,
          mapCleared: false,
          mapSet: false,
          suggestionSet: false,
        }))
      );
    }
  }, [valueSummary, valueSummaryStatus]);

  useEffect(() => {
    const localMap = mapping.filter(
      (e) => e.outputName === controller?.key
    )?.[0];
    const convMap = (localMap?.conversions ?? []).filter(
      (e) =>
        e.conversionType === "CONVERT" || e.conversionType === "IN_DELETE_ROW"
    )?.[0]?.conversions;
    let changed = false;
    const safeValues = [...values];
    const updateList = [];
    for (const ind in safeValues) {
      const val = { ...safeValues[ind] };
      safeValues[ind] = val;
      const mapVal = convMap?.[val.key];
      const sugVal =
        controller.key === config.EXCLUDE_KEY
          ? config.CLAIMS_MAPPING_INCLUDE_VALUE
          : suggestionsMap?.[val.key];
      if (mapVal != null && mapVal !== val.mapping) {
        if (mapVal === unmappedKey || controller.valid(mapVal)) {
          val.mapping = mapVal;
          val.mapSet = true;
          changed = true;
        }
      } else if (mapVal == null && val.mapSet) {
        if (mapVal === unmappedKey || controller.valid(mapVal)) {
          val.mapping = mapVal;
          changed = true;
        }
      } else if (
        sugVal != null &&
        mapVal == null &&
        !val.mapSet &&
        !val.suggestionsSet
      ) {
        if (controller.valid(sugVal)) {
          val.mapping = sugVal;
          val.suggestionsSet = true;
          changed = true;
          updateList.push({
            outputName: controller?.key,
            valueKey: val.key,
            mappedKey: sugVal,
          });
        }
      }
    }
    if (changed) {
      setValues(safeValues);
      if (updateList) {
        updateValueMapping(updateList);
      }
    }
  }, [
    mapping,
    values,
    setValues,
    suggestionsMap,
    controller,
    updateValueMapping,
  ]);

  useEffect(() => {
    if (controller && inputColumn != null) {
      const someNotMapped = values.some((cur) => (cur.mapping ?? "") === "");
      const allMapped = !someNotMapped && valueSummaryStatus === "fulfilled";
      setAllValuesMapped((cm) =>
        cm[controller.key] !== allMapped
          ? {
              ...cm,
              [controller.key]: allMapped,
            }
          : cm
      );
    }
  }, [controller, inputColumn, setAllValuesMapped, values, valueSummaryStatus]);

  if (!controller || inputColumn == null) {
    return <></>;
  }

  const totalMapped = values.reduce(
    (acc, cur) => (acc += (cur.mapping ?? "") === "" ? 0 : 1),
    0
  );

  const stats = {
    total: values.length,
    mapped: totalMapped,
    ignored: values.reduce(
      (acc, cur) => (acc += cur.mapping === ignoreKey ? cur.count : 0),
      0
    ),
    allMapped: values.length === totalMapped,
  };

  return (
    <Stack sx={columnSx}>
      <Header label={controller.label} stats={stats} />
      {valueSummaryStatus !== "fulfilled" ? (
        <Box sx={{ position: "relative", minHeight: "30vh" }}>
          <CentredLoader />
        </Box>
      ) : (
        <>
          {values.map(({ key, count, mapping }) => (
            <Stack
              key={key}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ padding: "1rem" }}
            >
              <Stack flexShrink={0}>
                <Typography
                  variant={"subtitle1"}
                  color={mapping === ignoreKey ? "silver" : null}
                  sx={{
                    textDecoration:
                      mapping === ignoreKey ? "line-through" : null,
                  }}
                >
                  {key || "[Blank]"}
                </Typography>
                <Typography variant={"caption"}>
                  {count}
                  {" items"}
                </Typography>
              </Stack>
              <FormControl fullWidth sx={{ maxWidth: "50%" }}>
                {suggestionsMap == null ? (
                  <Skeleton sx={{ transform: "none", height: "56px" }} />
                ) : (
                  <Select
                    value={mapping ?? unmappedKey}
                    sx={{
                      background: mapping === ignoreKey ? "#f3f4f7" : null,
                    }}
                    onChange={(e) => {
                      updateValueMapping([
                        {
                          outputName: controller.key,
                          valueKey: key,
                          mappedKey: e.target.value,
                        },
                      ]);
                    }}
                  >
                    <MenuItem value={ignoreKey}>
                      {"Ignore these items"}
                    </MenuItem>
                    <Divider />
                    {controller.options.map((option) => (
                      <MenuItem value={option.key} key={option.key}>
                        {option.display}
                      </MenuItem>
                    ))}
                    <Divider />
                    <MenuItem value={unmappedKey}>{"Clear mapping"}</MenuItem>
                  </Select>
                )}
              </FormControl>
            </Stack>
          ))}
          {stats.ignored > 0 && (
            <Chip
              label={stats.ignored + " items will not be imported"}
              variant={"outlined"}
              icon={<InfoIcon />}
              sx={{ alignSelf: "center", margin: "1rem 0" }}
              className={"fade-in-slow"}
              color={"secondary"}
            />
          )}
        </>
      )}
    </Stack>
  );
};

const extractCoverageConversion = (mapping) => {
  const conversion = { conversionType: "CONSTANT", value: null };
  if (mapping == null) return conversion;
  return (
    mapping.filter((x) => x.outputName === coverageKey)?.[0]
      ?.conversions?.[0] ?? conversion
  );
};

const CoverageColumn = ({
  controllers,
  mapping,
  updateConstantConversion,
  setAllValuesMapped,
}) => {
  const coverage =
    controllers?.filter((x) => x.key === coverageKey)?.[0].options ?? [];

  const conversion = extractCoverageConversion(mapping);

  const stats = {
    total: 1,
    mapped: conversion.value == null ? 0 : 1,
    allMapped: conversion.value != null,
  };

  useEffect(() => {
    setAllValuesMapped((all) => ({
      ...all,
      [coverageKey]: !!conversion.value,
    }));
  }, [conversion, setAllValuesMapped]);

  return (
    <Stack sx={columnSx}>
      <Header label={"Coverage"} stats={stats} />
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ padding: "1rem" }}
      >
        <Typography variant={"subtitle1"} flexShrink={0}>
          {"Value for all items"}
        </Typography>
        <FormControl fullWidth sx={{ maxWidth: "50%" }}>
          <Select
            value={conversion.value ?? ""}
            onChange={(e) =>
              updateConstantConversion(
                coverageKey,
                e.target.value === "" ? null : e.target.value
              )
            }
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            <Divider />
            {coverage.map((x) => (
              <MenuItem value={x.key} key={x.key}>
                {x.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
};

const ValueMapper = ({
  fileId,
  tableName,
  origin,
  headerRows,
  mapping,
  updateValueMapping,
  updateConstantConversion,
  controllers,
  setAllValuesMapped,
}) => {
  const showCoverageMapping =
    extractCoverageConversion(mapping).conversionType === "CONSTANT";

  return (
    <Paper elevation={0} sx={{ padding: "1rem" }}>
      <Stack direction={"row"} gap={2}>
        {controllers?.map((controller) => (
          <Column
            key={controller.key}
            fileId={fileId}
            tableName={tableName}
            origin={origin}
            headerRows={headerRows}
            controller={controller}
            mapping={mapping}
            updateValueMapping={updateValueMapping}
            setAllValuesMapped={setAllValuesMapped}
          />
        ))}
        {showCoverageMapping && (
          <CoverageColumn
            controllers={controllers}
            mapping={mapping}
            updateConstantConversion={updateConstantConversion}
            setAllValuesMapped={setAllValuesMapped}
          />
        )}
      </Stack>
    </Paper>
  );
};

export default ValueMapper;
