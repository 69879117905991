import * as staticDataService from "../../../services/staticDataService";
import { useGetColumnMappingSuggestionsQuery } from "../../../services/suggestionService";
import {
  useGetColumnTypeSummaryQuery,
  useGetHeaderRowQuery,
  useGetTablePageQuery,
} from "../../../services/tablesService";
import CentredLoader from "./CentredLoader";
import {
  TuneRounded as SettingsIcon,
  WarningRounded as MissingIcon,
  InfoOutlined as OptionalIcon,
  CheckRounded as ActiveIcon,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  List,
  ListItem,
  Paper,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
  ListItemButton,
} from "@mui/material";
import Button from "components/common/Button";
import DefaultLoader from "components/common/DefaultLoader";
import SummaryText from "components/common/SummaryText";
import { STYLE } from "components/common/SummaryText";
import { Fragment, useEffect, useState } from "react";

const HeaderAction = ({
  mappedColumn,
  requiredMissing,
  tip,
  isLoading,
  onClick,
}) => {
  if (isLoading) {
    return (
      <DefaultLoader
        color={"#fa7b35"}
        type={"TailSpin"}
        size={18}
        style={{ marginRight: "6px", fontSize: "18px", lineHeight: "12px" }}
      />
    );
  }

  if (mappedColumn) {
    return (
      <IconButton onClick={onClick}>
        <SettingsIcon />
      </IconButton>
    );
  }

  const title = requiredMissing
    ? "This column is required"
    : tip || "This column is optional";
  const icon = requiredMissing ? <MissingIcon /> : <OptionalIcon />;

  return (
    <Tooltip title={title} arrow>
      <IconButton onClick={onClick}>{icon}</IconButton>
    </Tooltip>
  );
};

const ColumnMappingHeader = ({
  title,
  mappedColumn,
  requiredMissing,
  tip,
  isLoading,
  onClick,
}) => {
  const background = requiredMissing
    ? "#ffefee"
    : mappedColumn == null
    ? "#f3f4f7"
    : "#effff7";
  return (
    <TableCell
      sx={{
        padding: "4px 4px 4px 8px",
        verticalAlign: "top !important",
        borderRight: "1px solid gainsboro",
        background: background,
      }}
    >
      <Stack
        flexDirection={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Stack>
          <Typography variant={"subtitle1"}>{title}</Typography>
          <Typography variant={"caption"}>
            {isLoading ? "-" : mappedColumn || "Unmapped"}
          </Typography>
        </Stack>
        <HeaderAction
          mappedColumn={mappedColumn}
          requiredMissing={requiredMissing}
          tip={tip}
          isLoading={isLoading}
          onClick={onClick}
        />
      </Stack>
    </TableCell>
  );
};

const FULFILLED = "fulfilled";

const ColumnMapper = ({
  fileId,
  origin,
  tableName,
  headerRows,
  updateColumns,
  targetColumnList,
  setTargetColumnList,
  targetColumnMap,
  setTargetColumnMap,
  sourceHeaders,
  setSourceHeaders,
  requiredColumns,
  columnTips,
}) => {
  const {
    data: configuration,
    status: configurationStatus,
  } = staticDataService.useMultiConfigQuery(["claims_files", "translations"]);

  const request = {
    fileId,
    tableName,
    skipRows: origin.row,
    skipColumns: origin.column,
    headerRows,
  };

  const {
    data: sourceColumns,
    status: sourceColumnsStatus,
  } = useGetHeaderRowQuery(request);

  const {
    data: columnTypes,
    status: columnTypesStatus,
  } = useGetColumnTypeSummaryQuery(request);

  useEffect(() => {
    if (
      configurationStatus === FULFILLED &&
      sourceColumnsStatus === FULFILLED
    ) {
      const notEqual = (listA, listB) => {
        if (listA?.length !== listB?.length) return true;
        if (listA === listB) return false;
        for (const i in listA) if (listA[i] !== listB[i]) return true;
        return false;
      };

      const headers = sourceColumns?.header ?? [];
      let headersChanged = notEqual(headers, sourceHeaders);
      if (headersChanged) {
        setSourceHeaders(headers);
      }
      const colNames = configuration.claims_files.mappedClaimsColumns ?? [];
      const colNamesChanged = notEqual(targetColumnList, colNames);
      if (colNamesChanged) {
        setTargetColumnList(colNames);
      }
      if (colNamesChanged || headersChanged) {
        const nameMap = configuration.translations?.claimsFileColumns || {};
        const targetMap = {};
        colNames.forEach((column) => {
          targetMap[column] = {
            column,
            label: nameMap[column] ?? column,
            required: requiredColumns.includes(column),
            tip: columnTips[column],
            suggestions: null,
            mappedColumnIndex: null,
            mappedColumnName: null,
          };
        });
        setTargetColumnMap(targetMap);
      }
    }
  }, [
    sourceColumns,
    sourceColumnsStatus,
    configuration,
    configurationStatus,
    fileId,
    tableName,
    targetColumnMap,
    targetColumnList,
    setTargetColumnList,
    setTargetColumnMap,
    sourceHeaders,
    setSourceHeaders,
    columnTips,
    requiredColumns,
  ]);

  const {
    data: suggestions,
    status: suggestionsStatus,
  } = useGetColumnMappingSuggestionsQuery(columnTypes?.types, {
    skip: !columnTypes || columnTypesStatus !== FULFILLED,
  });

  const { data: dataPage, status: dataPageStatus } = useGetTablePageQuery(
    {
      ...request,
      skipRows: request.skipRows + (request.headerRows || 1),
    },
    { skip: !fileId || !tableName }
  );

  const columnNameMapping = (targetName, sourceColumn) => {
    return {
      outputName: targetName,
      inputColumn: sourceColumn,
      conversionType: ["_OPEN_CLOSED", "_COVERAGE"].includes(targetName)
        ? "CONVERT"
        : ["_EXCLUDE"].includes(targetName)
        ? "IN_DELETE_ROW"
        : "",
    };
  };
  useEffect(() => {
    const suggestionsList = suggestions?.suggestions;
    if (suggestionsStatus === FULFILLED && sourceColumnsStatus === FULFILLED) {
      let changed = false;
      const init = { ...targetColumnMap };
      const columnUpdates = [];
      for (const column in targetColumnMap) {
        const suggest = suggestionsList[column];
        if (!!suggest && !init[column].suggestions) {
          init[column] = {
            ...targetColumnMap[column],
            suggestions: suggest,
          };
          if (
            init[column].mappedColIndex == null &&
            (suggest?.length || 0) > 0 &&
            suggest[0].confidence > 0.1
          ) {
            const suggestedIndex = sourceColumns?.header.indexOf(
              suggest[0].suggestion
            );
            init[column].mappedColIndex = suggestedIndex;
            init[column].mappedColName = suggest[0].suggestion;
            columnUpdates.push(columnNameMapping(column, suggestedIndex));
          }
          changed = true;
        }
      }
      if (changed) {
        setTargetColumnMap(init);
        updateColumns(columnUpdates);
      }
    }
  }, [
    suggestionsStatus,
    suggestions,
    targetColumnMap,
    sourceColumns,
    sourceColumnsStatus,
    updateColumns,
    setTargetColumnMap,
  ]);

  const [editColumn, setEditColumn] = useState(null);

  const summaryCounts = [
    {
      label: "Mapped",
      style: STYLE.POSITIVE,
      count: Object.values(targetColumnMap || []).reduce(
        (acc, cur) => (acc += cur.mappedColIndex != null ? 1 : 0),
        0
      ),
    },
    {
      label: "Missing",
      style: STYLE.NEGATIVE,
      count: Object.values(targetColumnMap || []).reduce(
        (acc, cur) =>
          (acc += cur.required && cur.mappedColIndex == null ? 1 : 0),
        0
      ),
    },
    {
      label: "Optional",
      style: STYLE.NEUTRAL,
      count: Object.values(targetColumnMap || []).reduce(
        (acc, cur) =>
          (acc += !cur.required && cur.mappedColIndex == null ? 1 : 0),
        0
      ),
    },
  ];

  const updateMapping = (index, name) => {
    if (editColumn != null) {
      const targetColumnName = targetColumnList[editColumn];
      const colEntry = targetColumnMap[targetColumnName];
      if (colEntry.mappedColIndex !== index) {
        const updatedTargetColumns = {
          ...targetColumnMap,
          [targetColumnName]: {
            ...targetColumnMap[targetColumnName],
            mappedColIndex: index,
            mappedColName: name,
          },
        };
        setTargetColumnMap(updatedTargetColumns);
        updateColumns([columnNameMapping(targetColumnName, index)]);
      }
      setEditColumn(null);
    }
  };

  if (
    suggestionsStatus !== FULFILLED ||
    sourceColumnsStatus !== FULFILLED ||
    dataPageStatus !== FULFILLED
  ) {
    return <CentredLoader />;
  }

  const page = dataPage?.page || [];

  const toggleEditColumn = (newColumn) => {
    setEditColumn(newColumn === editColumn ? null : newColumn);
  };
  return (
    <Paper elevation={0} sx={{ padding: "1rem" }}>
      <Box marginBottom={1}>
        <SummaryText
          counts={summaryCounts}
          defaultText={
            "Please choose the columns containing the relevant loss data"
          }
        />
      </Box>
      <TableContainer
        sx={{ overflowX: "initial", border: "1px solid gainsboro" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {targetColumnList.map((targetColName, targetColIndex) => {
                const targetColumn = targetColumnMap[targetColName];
                return (
                  <ColumnMappingHeader
                    key={targetColIndex}
                    title={targetColumn.label}
                    mappedColumn={
                      targetColumn.mappedColIndex == null
                        ? null
                        : sourceColumns.header?.[targetColumn.mappedColIndex]
                    }
                    requiredMissing={
                      targetColumn.mappedColIndex == null &&
                      targetColumn.required
                    }
                    tip={targetColumn.tip}
                    isLoading={false}
                    onClick={() => toggleEditColumn(targetColIndex)}
                  />
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {page.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {targetColumnList.map((colName, colIndex) => {
                  const colEntry = targetColumnMap?.[colName] || {};
                  const editColEntry =
                    editColumn == null
                      ? {}
                      : targetColumnMap?.[targetColumnList[editColumn]];
                  return editColumn != null &&
                    editColumn === colIndex &&
                    rowIndex !== 0 ? (
                    <Fragment key={colIndex}></Fragment>
                  ) : editColumn === colIndex && rowIndex === 0 ? (
                    <TableCell
                      key={colIndex}
                      rowSpan={page.length}
                      sx={{
                        verticalAlign: "top",
                        borderRight: "1px solid gainsboro",
                      }}
                      className={"fade-in"}
                    >
                      <Stack>
                        <List sx={{ width: "100%" }}>
                          {(sourceColumns.header ?? []).map((col, index) => (
                            <ListItem key={index} sx={{ padding: 0 }}>
                              <ListItemButton
                                onClick={() => updateMapping(index, col)}
                              >
                                <Stack
                                  flexDirection={"row"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                  sx={{ width: "100%" }}
                                >
                                  <Stack>
                                    <Typography variant={"subtitle1"}>
                                      {col}
                                    </Typography>
                                    <Typography variant={"caption"}>
                                      {page[0]?.[index]}
                                    </Typography>
                                  </Stack>
                                  {index === editColEntry.mappedColIndex && (
                                    <ActiveIcon />
                                  )}
                                </Stack>
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                        <Stack gap={1}>
                          <Button
                            sx={{ width: "100%" }}
                            onClick={() => setEditColumn(null)}
                          >
                            {"Done"}
                          </Button>
                          <Button
                            sx={{ width: "100%" }}
                            onClick={() => updateMapping(null, null)}
                            color={"secondary"}
                          >
                            {"Clear Mapping"}
                          </Button>
                        </Stack>
                      </Stack>
                    </TableCell>
                  ) : (
                    <TableCell
                      sx={{ borderRight: "1px solid gainsboro" }}
                      key={"display-cell-" + rowIndex + "-" + colIndex}
                    >
                      {colEntry.mappedColIndex == null
                        ? ""
                        : row[colEntry.mappedColIndex]}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ColumnMapper;
