import AdminApp from "./AdminApp";
import AttachmentsApp from "./AttachmentsApp";
import Logout from "./Logout";
import ReportApp from "./ReportApp";
import ReviewApp from "./ReviewApp";
import ScenariosApp from "./ScenariosApp";
import SubmissionApp from "./SubmissionApp";
import TokenApp from "./TokenApp";
import WarnOnPageExit from "./WarnOnPageExit";
import AppBar from "./common/AppBar";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Tracker from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";
import * as Sentry from "@sentry/react";
import theme from "components/common/Theme";
import MaintenanceBlocker from "components/maintenance/MaintenanceBlocker";
import * as config from "config";
import { SnackbarProvider } from "notistack";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import * as trackerHooks from "store/hooks/trackerHooks";
import * as userSelectors from "store/selectors/user/userSelectors";

const tracker =
  config.getOpenReplayProjectId() != null
    ? new Tracker({
        projectKey: config.getOpenReplayProjectId(),
        __DISABLE_SECURE_MODE: config.getOpenReplayDisableSecureMode(),
      })
    : null;
tracker?.use(trackerAssist({}));
tracker?.start({
  metadata: {
    environment:
      process.env.REACT_APP_SENTRY_ENVIRONMENT || "UNKNOWN_ENVIRONMENT",
  },
});

const RootApp = ({ email, companyId, isSuperUser }) => {
  trackerHooks.useHeap();

  useEffect(() => {
    Sentry.setUser({ email });
    tracker?.setUserID(email);
    tracker?.setMetadata("companyId", companyId);
  }, [email, companyId]);

  return (
    <ThemeProvider theme={theme}>
      <MaintenanceBlocker>
        <SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AppBar />
            <WarnOnPageExit>
              <Routes>
                <Route path={"/logout"} element={<Logout />} />
                <Route path={"/token/*"} element={<TokenApp />} />
                <Route
                  path={"/transfers/*"}
                  element={<SubmissionApp source={"transfers"} />}
                />
                <Route path={"/submissions/*"} element={<SubmissionApp />} />
                <Route path={"/reports/*"} element={<ReportApp />} />
                <Route path={"/review/*"} element={<ReviewApp />} />
                <Route path={"/scenarios/*"} element={<ScenariosApp />} />
                <Route path={"/attachments/*"} element={<AttachmentsApp />} />
                <Route path={"/admin/*"} element={<AdminApp />} />
                {isSuperUser ? (
                  <Route path={"*"} element={<Navigate to={"/admin"} />} />
                ) : (
                  <Route
                    path={"*"}
                    element={<Navigate to={"/submissions"} />}
                  />
                )}
              </Routes>
            </WarnOnPageExit>
          </LocalizationProvider>
        </SnackbarProvider>
      </MaintenanceBlocker>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  const userAttributes = userSelectors.selectUserAttributes(state);
  return {
    email: userAttributes?.email || "UNKNOWN_USER",
    companyId: userAttributes?.["custom:company_id"] || "UNKNOWN_COMPANY",
    isSuperUser: userSelectors.isSuperUser(state),
  };
};

export default connect(mapStateToProps)(RootApp);
