import BusinessUnitSelector from "./BusinessUnitSelector";
import PolicyType from "./PolicyType";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import * as numbers from "common/numbers";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import StatusSelectV2 from "components/common/StatusSelectV2";
import Toggle from "components/common/Toggle";
import Visible from "components/common/Visible";
import PolicyNumberField from "components/layers/PolicyNumberField";
import ReinsuranceSelector from "components/layers/ReinsuranceSelector";
import PaymentTermsForm from "components/payments/PaymentTermsForm";
import PolicyForm from "components/pricing/layerPricing/PolicyForm";
import * as config from "config";
import { Fragment } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as programActions from "store/actions/input/program/programActions";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as staticDataHooks from "store/hooks/staticDataHooks";
import * as programPricingStore from "store/programPricing";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const IncludeSir = ({ layer }) => {
  const dispatch = useDispatch();

  const sirs = useSelector(claimsSelectors.selectSelfInsuredRetentions);

  const includeSir = layer?.includeSir ?? null;

  const setIncludeSir = (lossType) => {
    dispatch(
      pricingActions.updateLayer({
        layerId: layer?.id,
        values: {
          includeSir: lossType,
        },
      })
    );
  };

  return (
    <Disabled ifReadOnly>
      <Autocomplete
        value={sirs.filter((sir) => sir.lossType === includeSir)?.[0] ?? null}
        onChange={(_, value) => setIncludeSir(value?.lossType ?? null)}
        options={sirs}
        getOptionLabel={(option) =>
          `${numbers.abbreviated(option.value)} (${option.lossType})`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={"Include SIR"}
            variant={"filled"}
            InputLabelProps={{ shrink: true }}
          />
        )}
        autoHighlight
      />
    </Disabled>
  );
};

const RegionalCoverage = ({ layerId }) => {
  const dispatch = useDispatch();

  const regionalCoverage = useSelector(programPricingStore.select)?.layers?.[
    layerId
  ]?.regionalCoverage;

  const setRegionalCoverage = (regionalCoverage) => {
    dispatch(
      programPricingStore.updatePathValue({
        path: ["layers", layerId, "regionalCoverage"],
        value: regionalCoverage,
      })
    );
  };

  const { data: catExposuresConfig } = staticDataService.useConfigQuery(
    "cat_exposures"
  );

  const translateLabel = staticDataHooks.useTranslator("uiLabels");

  const regionalCoverageOptions =
    catExposuresConfig?.enums?.regionalCoverage?.choices ?? [];

  return (
    <Disabled ifReadOnly>
      <Autocomplete
        value={
          regionalCoverageOptions.filter(
            (coverage) => coverage.key === regionalCoverage
          )?.[0] ?? (regionalCoverage == null ? null : { name: "<Unknown>" })
        }
        onChange={(_, value) => setRegionalCoverage(value?.key ?? null)}
        options={regionalCoverageOptions}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translateLabel("REGIONAL_COVERAGE", { default: "Regional" })}
            variant={"filled"}
            InputLabelProps={{ shrink: true }}
          />
        )}
        autoHighlight
      />
    </Disabled>
  );
};

const PerilsCoverage = ({ layerId }) => {
  const dispatch = useDispatch();

  const perilsCoverage = useSelector(programPricingStore.select)?.layers?.[
    layerId
  ]?.perilsCoverage;

  const setPerilsCoverage = (perilsCoverage) => {
    dispatch(
      programPricingStore.updatePathValue({
        path: ["layers", layerId, "perilsCoverage"],
        value: perilsCoverage,
      })
    );
  };

  const { data: catExposuresConfig } = staticDataService.useConfigQuery(
    "cat_exposures"
  );

  const translateLabel = staticDataHooks.useTranslator("uiLabels");

  const perilsCoverageOptions =
    catExposuresConfig?.enums?.perilsCoverage?.choices ?? [];

  return (
    <Disabled ifReadOnly>
      <Autocomplete
        value={
          perilsCoverageOptions.filter(
            (coverage) => coverage.key === perilsCoverage
          )?.[0] ?? (perilsCoverage == null ? null : { name: "<Unknown>" })
        }
        onChange={(_, value) => setPerilsCoverage(value?.key ?? null)}
        options={perilsCoverageOptions}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translateLabel("PERILS_COVERAGE", { default: "Perils" })}
            variant={"filled"}
            InputLabelProps={{ shrink: true }}
          />
        )}
        autoHighlight
      />
    </Disabled>
  );
};

const ActiveLayerEditor = ({
  title = "Active Layer",
  subtitle,
  error,
  ...props
}) => {
  if (props.layer == null) {
    return <></>;
  }

  const translateLabel = staticDataHooks.useTranslator("uiLabels");
  const generatePolicyLocked =
    props.generatePolicyLockUntilBound && !props.layer.bound;

  return (
    <Component title={title} subtitle={subtitle} error={error}>
      {props.layer.customOverrides && (
        <Alert severity={"warning"} sx={{ marginBottom: config.GRID_SPACING }}>
          {"Custom overrides have been applied to this layer. These will be"}
          {"reflected in the bordereau report but not in Marmalade."}
          <br />
          <br />
          {Object.entries(props.layer.customOverrides).map(([k, v]) => (
            <Fragment key={k}>
              {k}
              {":"}
              <br />
              {Object.entries(v).map(([k, v]) => (
                <Fragment key={k}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{k}
                  {": "}
                  {JSON.stringify(v)}
                  <br />
                </Fragment>
              ))}
            </Fragment>
          ))}
          <br />
          <br />
          <Button
            variant={"contained"}
            color={"error"}
            onClick={() => props.deleteActiveLayerCustomOverrides()}
          >
            {"Delete These Overrides"}
          </Button>
        </Alert>
      )}
      <Grid container spacing={config.GRID_SPACING}>
        <Grid item xs={6}>
          <PrettyNumberTextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            label={"Limit"}
            id={"layer-pricing-limit"}
            data-testid={"layer-pricing-limit"}
            variant={"filled"}
            onChangeNumber={props.updateActiveLayerLimit}
            value={props.layer.limit}
            disabled={props.layer.loadingPurePremium}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PrettyNumberTextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            label={"Attachment"}
            id={"layer-pricing-attachment"}
            data-testid={"layer-pricing-attachment"}
            variant={"filled"}
            onChangeNumber={props.updateActiveLayerAttachment}
            value={props.layer.attachment}
            disabled={props.layer.loadingPurePremium}
            fullWidth
          />
        </Grid>
        <Visible
          byTag={"<ActiveLayerEditor>.includeSir"}
          defaultVisible={false}
        >
          <Grid item xs={6}>
            <IncludeSir layer={props.layer} />
          </Grid>
          <Grid item xs={6}>
            <PrettyNumberTextField
              type={"text"}
              InputLabelProps={{ shrink: true }}
              label={"Effective Attachment"}
              data-testid={"layer-effective-attachment"}
              variant={"filled"}
              value={props.layer.effectiveAttachment ?? null}
              disabled={true}
              fullWidth
            />
          </Grid>
        </Visible>
        <Visible
          byTag={"<ActiveLayerEditor>.aggregateLimit"}
          defaultVisible={false}
        >
          <Grid item xs={6}>
            <PrettyNumberTextField
              type={"text"}
              InputLabelProps={{ shrink: true }}
              label={"Aggregate Limit"}
              id={"layer-pricing-aggregate-limit"}
              variant={"filled"}
              onChangeNumber={(aggregateLimit) => {
                props.updateLayer({
                  layerId: props.layer.id,
                  values: { aggregateLimit },
                });
              }}
              value={props.layer.aggregateLimit}
              disabled={props.layer.loadingPurePremium}
              fullWidth
            />
          </Grid>
        </Visible>
        <Grid item xs={6}>
          <PrettyNumberTextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            label={translateLabel("REQUIRED_PREMIUM", {
              default: "100% Premium",
            })}
            id={"layer-pricing-gross-premium"}
            variant={"filled"}
            onChangeNumber={props.updateActiveLayerGrossPremium}
            value={props.layer.grossPremium}
            disabled={props.layer.loadingPurePremium}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PrettyNumberTextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            label={"Gross PPM"}
            id={"layer-pricing-gross-ppm"}
            variant={"filled"}
            onChangeNumber={props.updateActiveLayerGrossPPM}
            value={props.layer.grossPPM}
            disabled={props.layer.loadingPurePremium}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PrettyNumberTextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            label={"Our Limit"}
            id={"layer-pricing-line-size"}
            variant={"filled"}
            onChangeNumber={props.updateActiveLayerLineSizeAbsolute}
            value={props.layer.lineSize}
            disabled={props.layer.loadingPurePremium}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <PrettyNumberTextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            label={"Our Share"}
            id={"layer-pricing-share-of-line"}
            variant={"percentage"}
            onChangeNumber={props.updateActiveLayerShareOfLine}
            value={props.layer.shareOfLine}
            disabled={props.layer.loadingPurePremium}
            fullWidth
          />
        </Grid>
        <Visible byTag={"<ActiveLayerEditor>.TRIA"} defaultVisible={false}>
          <Grid item xs={6}>
            <PrettyNumberTextField
              type={"text"}
              InputLabelProps={{ shrink: true }}
              label={"TRIA"}
              id={"layer-pricing-tria"}
              variant={"percentage"}
              onChangeNumber={props.updateActiveLayerTRIA}
              value={props.layer.TRIA ?? 0}
              disabled={props.layer.loadingPurePremium}
              fullWidth
            />
          </Grid>
        </Visible>
        <Visible
          byTag={"<ActiveLayerEditor>.TRIAAmount"}
          defaultVisible={false}
        >
          <Grid item xs={6}>
            <PrettyNumberTextField
              type={"text"}
              InputLabelProps={{ shrink: true }}
              label={"TRIA Amount"}
              id={"layer-pricing-tria-premium"}
              variant={"filled"}
              disabled={true}
              value={props.layer.TRIAAmount ?? 0}
              fullWidth
            />
          </Grid>
        </Visible>
        <Visible
          byTag={"<ActiveLayerEditor>.shareOfPremiumVisible"}
          defaultVisible={true}
        >
          <Grid item xs={6}>
            <Disabled
              byTag={"<ActiveLayerEditor>.shareOfPremium"}
              defaultDisabledByTag={true}
              force={props.layer.loadingPurePremium}
            >
              <PrettyNumberTextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                label={"Our Premium"}
                id={"layer-pricing-ark-premium"}
                variant={"filled"}
                onChangeNumber={props.updateActiveLayerShareOfPremium}
                value={props.layer.shareOfPremium}
                fullWidth
              />
            </Disabled>
          </Grid>
        </Visible>
        <Visible
          byTag={"<ActiveLayerEditor>.shareOfPremiumWithTRIA"}
          defaultVisible={false}
        >
          <Grid item xs={6}>
            <PrettyNumberTextField
              type={"text"}
              InputLabelProps={{ shrink: true }}
              label={"Our Premium (Inc. TRIA)"}
              id={"layer-pricing-share-of-premium-with-tria"}
              variant={"filled"}
              disabled={true}
              value={props.layer.shareOfPremiumWithTRIA ?? 0}
              fullWidth
            />
          </Grid>
        </Visible>
        <Grid item xs={6}>
          <PrettyNumberTextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            label={"Authorized Limit"}
            id={"layer-pricing-authorized-limit"}
            variant={"filled"}
            onChangeNumber={props.updateActiveLayerAuthorizedLimit}
            value={props.layer.authorizedLimit}
            disabled={props.layer.loadingPurePremium}
            fullWidth
          />
        </Grid>
        <Visible byTag={"<ActiveLayerEditor>.puniWrap"} defaultVisible={false}>
          <Grid item container xs={12} spacing={config.GRID_SPACING}>
            <Grid item xs={6}>
              <PrettyNumberTextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                label={"Puni Wrap (%)"}
                variant={"percentage"}
                value={props.layer.puniWrapPct ?? 0}
                onChangeNumber={(puniWrapPct) =>
                  props.updateLayer({
                    layerId: props.layer.id,
                    values: { puniWrapPct },
                  })
                }
                disabled={props.layer.loadingPurePremium}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <PrettyNumberTextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                label={"Our Puni Wrap Premium"}
                variant={"filled"}
                disabled={true}
                value={props.layer.shareOfPuniWrap ?? 0}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label={"Puni Included?"}
                control={
                  <Checkbox
                    checked={props.layer.isPuniWrapIncluded}
                    onChange={(e) =>
                      props.updateLayer({
                        layerId: props.layer.id,
                        values: {
                          isPuniWrapIncluded: e.target.checked,
                        },
                      })
                    }
                    disabled={props.layer.loadingPurePremium}
                  />
                }
                componentsProps={{
                  typography: {
                    variant: "caption",
                  },
                }}
                sx={{
                  height: "100%",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <PrettyNumberTextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                label={"Our Premium ex Puni Wrap"}
                variant={"filled"}
                disabled={true}
                value={props.layer.shareOfPremiumExPuniWrap ?? 0}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <PrettyNumberTextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                label={"Puni Brokerage (%)"}
                variant={"percentage"}
                value={props.layer.puniWrapBrokeragePct ?? 0}
                onChangeNumber={(puniWrapBrokeragePct) =>
                  props.updateLayer({
                    layerId: props.layer.id,
                    values: { puniWrapBrokeragePct },
                  })
                }
                disabled={props.layer.loadingPurePremium}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <PrettyNumberTextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                label={"Our Puni Wrap Brokerage"}
                variant={"filled"}
                disabled={true}
                value={props.layer.shareOfPuniWrapBrokerage ?? 0}
                fullWidth
              />
            </Grid>
          </Grid>
        </Visible>
        <Visible byTag={"<ActiveLayerEditor>.facPct"} defaultVisible={false}>
          <Grid item xs={6}>
            <PrettyNumberTextField
              type={"text"}
              InputLabelProps={{ shrink: true }}
              label={"Fac (%)"}
              variant={"percentage"}
              value={props.layer.facPct ?? 0}
              onChangeNumber={(facPct) =>
                props.updateLayer({
                  layerId: props.layer.id,
                  values: { facPct },
                })
              }
              fullWidth
            />
          </Grid>
        </Visible>
        <Visible
          byTag={"<ActiveLayerEditor>.facPremium"}
          defaultVisible={false}
        >
          <Grid item xs={6}>
            <PrettyNumberTextField
              type={"text"}
              InputLabelProps={{ shrink: true }}
              label={"Fac Premium"}
              variant={"filled"}
              value={props.layer.facPremium ?? 0}
              onChangeNumber={(facPremium) =>
                props.updateLayer({
                  layerId: props.layer.id,
                  values: { facPremium },
                })
              }
              fullWidth
            />
          </Grid>
        </Visible>
        <Grid item xs={12}>
          <FormattedDatePicker
            label={"Inception"}
            onChangeDate={props.updateInception}
            value={props.program.inception}
            disabled={props.layer.loadingPurePremium}
            fullWidth
            clearable={false}
          />
        </Grid>
        <Grid item container xs={12} spacing={config.GRID_SPACING}>
          <Grid item xs={6}>
            <PrettyNumberTextField
              type={"text"}
              InputLabelProps={{ shrink: true }}
              InputProps={{ spellCheck: "false" }}
              label={"Brokerage"}
              autoComplete={"off"}
              id={"program-inputs-brokerage"}
              variant={"percentage"}
              onChangeNumber={props.updateActiveLayerBrokerage}
              value={props.layer.brokerage}
              disabled={props.layer.loadingPurePremium}
              fullWidth
            />
          </Grid>
          <Visible
            defaultVisible={false}
            byTag={"<ActiveLayerEditor>.slip-fee"}
          >
            <Grid item xs={6}>
              <PrettyNumberTextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                InputProps={{ spellCheck: "false" }}
                label={"Slip Fee"}
                autoComplete={"off"}
                variant={"percentage"}
                onChangeNumber={(slipFee) =>
                  props.updateLayer({
                    layerId: props.layer.id,
                    values: { slipFee },
                  })
                }
                value={props.layer.slipFee}
                disabled={props.layer.loadingPurePremium}
                fullWidth
              />
            </Grid>
          </Visible>
          <Tooltip title={"Other Acquisition Costs %"}>
            <Grid item xs={6}>
              <PrettyNumberTextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                InputProps={{ spellCheck: "false" }}
                label={"OAC"}
                autoComplete={"off"}
                id={"program-inputs-other-acquisition-costs"}
                variant={"percentage"}
                onChangeNumber={props.updateActiveLayerOtherAcquisitionCosts}
                value={props.layer.otherAcquisitionCosts}
                disabled={props.layer.loadingPurePremium}
                fullWidth
              />
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item container spacing={config.GRID_SPACING}>
          <Visible
            defaultVisible={true}
            byTag={"<ActiveLayerEditor>.target-profit"}
          >
            <Grid item xs={6}>
              <PrettyNumberTextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                InputProps={{ spellCheck: "false" }}
                label={translateLabel("TARGET_PROFIT", {
                  default: "Target Profit",
                })}
                autoComplete={"off"}
                id={"program-inputs-profit"}
                variant={"percentage"}
                onChangeNumber={props.updateActiveLayerProfit}
                value={props.layer.profit}
                disabled={props.layer.loadingPurePremium}
                fullWidth
              />
            </Grid>
          </Visible>
          <Visible defaultVisible={false} byTag={"<ActiveLayerEditor>.gnulr"}>
            <Grid item xs={6}>
              <Disabled
                byTag={"<ActiveLayerEditor>.gnulr"}
                defaultDisabledByTag={false}
              >
                <PrettyNumberTextField
                  type={"text"}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ spellCheck: "false" }}
                  label={"GNULR"}
                  autoComplete={"off"}
                  id={"program-inputs-profit"}
                  variant={"percentage"}
                  onChangeNumber={(e) => {
                    const inputValue = 100 - e ?? 100;
                    props.updateActiveLayerProfit(inputValue);
                  }}
                  value={100 - props.layer.profit}
                  disabled={props.layer.loadingPurePremium}
                  fullWidth
                />
              </Disabled>
            </Grid>
          </Visible>
          <Visible
            defaultVisible={false}
            byTag={"<ActiveLayerEditor>.minimumEarnedPremium"}
          >
            <Grid item xs={6}>
              <Disabled
                byTag={"<ActiveLayerEditor>.minimumEarnedPremium"}
                defaultDisabledByTag={false}
              >
                <PrettyNumberTextField
                  type={"text"}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ spellCheck: "false" }}
                  label={"Minimum Earned Premium"}
                  autoComplete={"off"}
                  id={"program-inputs-minimum-earned-premium"}
                  variant={"percentage"}
                  onChangeNumber={(e) => {
                    props.updateActiveLayerMinimumEarnedPremium(e);
                  }}
                  value={props.layer.minimumEarnedPremium}
                  disabled={props.layer.loadingPurePremium}
                  fullWidth
                />
              </Disabled>
            </Grid>
          </Visible>
          <Grid item xs={6}>
            <PrettyNumberTextField
              type={"text"}
              InputLabelProps={{ shrink: true }}
              InputProps={{ spellCheck: "false" }}
              label={"Expenses"}
              autoComplete={"off"}
              id={"program-inputs-expenses"}
              variant={"percentage"}
              onChangeNumber={props.updateActiveLayerExpenses}
              value={props.layer.expenses}
              disabled={props.layer.loadingPurePremium}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={config.GRID_SPACING}>
          <Visible
            byTag={"<ActiveLayerEditor>.regionalCoverage"}
            defaultVisible={false}
          >
            <Grid item xs={6}>
              <RegionalCoverage layerId={props?.layer?.id} />
            </Grid>
          </Visible>
          <Visible
            byTag={"<ActiveLayerEditor>.perilsCoverage"}
            defaultVisible={false}
          >
            <Grid item xs={6}>
              <PerilsCoverage layerId={props?.layer?.id} />
            </Grid>
          </Visible>
        </Grid>
        <Visible
          byTag={"<ActiveLayerEditor>.policyType"}
          defaultVisible={false}
        >
          <Disabled ifReadOnly>
            <PolicyType />
          </Disabled>
        </Visible>
        <PolicyForm disabled={props.layer.loadingPurePremium} />
        <Visible
          byTag={"<ActiveLayerEditor>.reinsurance"}
          defaultVisible={false}
        >
          <ReinsuranceSelector
            label={translateLabel("LAYER_REINSURANCE", { default: "Security" })}
            value={props.layer.paperProvider}
            onChange={(x) => {
              props.updateActiveLayerReinsurance(x);
            }}
            disabled={props.layer.loadingPurePremium}
          />
        </Visible>
        <Visible byTag={"<ActiveLayerEditor>.paymentTerms"}>
          <PaymentTermsForm
            paymentTerms={props.layer.paymentTerms}
            setPaymentTerms={props.updateActiveLayerPaymentTerms}
          />
        </Visible>
        <Visible byTag={"<ActiveLayerEditor>.businessUnit"}>
          <Grid item xs={12}>
            <BusinessUnitSelector
              value={props.layer.businessUnit}
              onChange={(businessUnit) =>
                props.updateLayer({
                  layerId: props.layer.id,
                  values: { businessUnit },
                })
              }
            />
          </Grid>
        </Visible>
        <Grid item xs={12}>
          <Toggle
            byTag={"<ActiveLayerEditor>.reference.generatePolicyNumber"}
            trueChild={
              <PolicyNumberField
                value={props.layer.reference}
                onChange={props.updateActiveLayerReference}
                disabled={props.layer.loadingPurePremium}
                generateLocked={generatePolicyLocked}
              />
            }
            falseChild={
              <TextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                label={"Reference"}
                id={"layer-pricing-reference"}
                variant={"filled"}
                onChange={(e) => {
                  let value = e.target.value;
                  if (!value || value === "") {
                    value = null;
                  }
                  props.updateActiveLayerReference(value);
                }}
                value={props.layer.reference ?? ""}
                disabled={props.layer.loadingPurePremium}
                fullWidth
              />
            }
          />
        </Grid>
        <Visible
          byTag={"<ActiveLayerEditor>.puniReference"}
          defaultVisible={false}
        >
          <Grid item xs={12}>
            <PolicyNumberField
              type={"puniReference"}
              label={"Puni Reference"}
              value={props.layer.puniReference}
              onChange={(puniReference) =>
                props.updateLayer({
                  layerId: props.layer.id,
                  values: { puniReference },
                })
              }
              disabled={props.layer.loadingPurePremium}
              generateLocked={generatePolicyLocked}
            />
          </Grid>
        </Visible>
        <Grid item xs={12}>
          <TextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            InputProps={{ spellCheck: "false" }}
            label={"Comment"}
            autoComplete={"off"}
            variant={"filled"}
            onChange={(e) => props.updateComment(e.target.value)}
            value={props.layer.comment || ""}
            fullWidth
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <StatusSelectV2
            status={props.layer.status}
            disabled={props.layer.loadingPurePremium}
            onChange={(status) => {
              props.updateActiveLayerStatus(status);
            }}
          />
        </Grid>
        <Visible
          byTag={"<ActiveLayerEditor>.isBinderIssued"}
          defaultVisible={true}
        >
          <Grid item xs={6}>
            <FormControlLabel
              label={translateLabel("IS_BINDER_ISSUED", {
                default: "Binder Issued?",
              })}
              control={
                <Checkbox
                  checked={props.layer.isBinderIssued ?? false}
                  onChange={(e) =>
                    props.updateLayer({
                      layerId: props.layer.id,
                      values: {
                        isBinderIssued: e.target.checked,
                      },
                    })
                  }
                  disabled={props.layer.loadingPurePremium}
                />
              }
              componentsProps={{
                typography: {
                  variant: "caption",
                },
              }}
              sx={{
                height: "100%",
              }}
            />
          </Grid>
        </Visible>
        <Visible
          byTag={"<ActiveLayerEditor>.isPolicyIssued"}
          defaultVisible={true}
        >
          <Grid item xs={6}>
            <FormControlLabel
              label={translateLabel("IS_POLICY_ISSUED", {
                default: "Policy Issued?",
              })}
              control={
                <Checkbox
                  checked={props.layer.isPolicyIssued ?? false}
                  onChange={(e) =>
                    props.updateLayer({
                      layerId: props.layer.id,
                      values: {
                        isPolicyIssued: e.target.checked,
                      },
                    })
                  }
                  disabled={props.layer.loadingPurePremium}
                />
              }
              componentsProps={{
                typography: {
                  variant: "caption",
                },
              }}
              sx={{
                height: "100%",
              }}
            />
          </Grid>
        </Visible>
      </Grid>
    </Component>
  );
};

export const mapDispatchToProps = {
  updateComment: pricingActions.updateComment,
  refreshActiveLayerPricing: pricingActions.refreshActiveLayerPricing,
  updateLayer: pricingActions.updateLayer,
  updateActiveLayerLimit: pricingActions.updateActiveLayerLimit,
  updateActiveLayerAttachment: pricingActions.updateActiveLayerAttachment,
  updateActiveLayerLineSizeAbsolute:
    pricingActions.updateActiveLayerLineSizeAbsolute,
  updateActiveLayerGrossPremium: pricingActions.updateActiveLayerGrossPremium,
  updateActiveLayerAuthorizedLimit:
    pricingActions.updateActiveLayerAuthorizedLimit,
  updateInception: programActions.updateInception,
  updateActiveLayerStatus: pricingActions.updateActiveLayerStatus,
  updateBaseLayerLimit: pricingActions.updateBaseLayerLimit,
  updateBaseLayerAttachment: pricingActions.updateBaseLayerAttachment,
  updateActiveLayerShareOfLine: pricingActions.updateActiveLayerShareOfLine,
  updateActiveLayerTRIA: pricingActions.updateActiveLayerTRIA,
  updateActiveLayerShareOfPremium:
    pricingActions.updateActiveLayerShareOfPremium,
  updateActiveLayerGrossPPM: pricingActions.updateActiveLayerGrossPPM,
  updateActiveLayerExpenses: pricingActions.updateActiveLayerExpenses,
  updateActiveLayerProfit: pricingActions.updateActiveLayerProfit,
  updateActiveLayerBrokerage: pricingActions.updateActiveLayerBrokerage,
  updateActiveLayerOtherAcquisitionCosts:
    pricingActions.updateActiveLayerOtherAcquisitionCosts,
  updateActiveLayerReference: pricingActions.updateActiveLayerReference,
  updateActiveLayerPaymentTerms: pricingActions.updateActiveLayerPaymentTerms,
  updateActiveLayerReinsurance: pricingActions.updateActiveLayerReinsurance,
  updateActiveLayerMinimumEarnedPremium:
    pricingActions.updateActiveLayerMinimumEarnedPremium,
  updateNote: pricingActions.updateLayerPricingNote,
  deleteActiveLayerCustomOverrides:
    pricingActions.deleteActiveLayerCustomOverrides,
};

export const mapStateToProps = (state) => {
  return {
    layer: pricingSelectors.selectResolvedActiveLayer(state),
    program: programSelectors.selectResolvedProgram(state),
    generatePolicyLockUntilBound:
      userSelectors.selectUserConfig(state)?.componentVisibility?.[
        "generatePolicyNumber.lockUntilBound"
      ] ?? false,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveLayerEditor);
