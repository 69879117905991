import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Amplify } from "aws-amplify";
import { I18n } from "aws-amplify/utils";
import * as config from "config";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

const passwordPolicyString =
  "Passwords must contain: " +
  "at least 16 characters, " +
  "at least 1 number, " +
  "at least 1 uppercase letter, " +
  "and at least 1 lowercase letter.";

Amplify.configure(config.AMPLIFY_CONFIG);

I18n.setLanguage("en");
I18n.putVocabularies({
  en: {
    "Password does not conform to policy: Password not long enough":
      "Password does not conform to policy: Password not long enough. " +
      passwordPolicyString,
    "Password does not conform to policy: Password must have lowercase characters":
      "Password does not conform to policy: Password must have lowercase characters. " +
      passwordPolicyString,
    "Password does not conform to policy: Password must have uppercase characters":
      "Password does not conform to policy: Password must have uppercase characters. " +
      passwordPolicyString,
    "Password does not conform to policy: Password must have numeric characters":
      "Password does not conform to policy: Password must have numeric characters. " +
      passwordPolicyString,
  },
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing(), new Sentry.Replay({})],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
