import SearchBar from "./SearchBar";
import SearchCard from "./SearchCard";
import { DATE_MODE, FILTER_MODE, SORT_ORDER } from "./constants";
import { AddRounded as AddIcon } from "@mui/icons-material";
import { Box, Fab, Link, Stack, Typography } from "@mui/material";
import { hasValue } from "common/strings";
import DefaultLoader from "components/common/DefaultLoader";
import OfflineBanner from "components/common/OfflineBanner";
import { SUPPORT_EMAIL } from "config";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as staticDataService from "services/staticDataService";
import * as submissionsService from "services/submissionsService";
import * as persistenceActions from "store/actions/persistence/persistenceActions";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as userSelectors from "store/selectors/user/userSelectors";

const NoMatches = ({ searchText }) => {
  const label =
    "No matches found" + (searchText === "" ? "" : ` for '${searchText}'`);
  return (
    <Stack
      className={"search-result"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        height: "100px",
        cursor: "default !important",
        boxShadow: "none !important",
      }}
    >
      {label}
    </Stack>
  );
};

const Error = () => {
  const mailLink =
    "mailto:" + SUPPORT_EMAIL + "?subject=Error%20Loading%20Search";

  return (
    <>
      <OfflineBanner />
      <Stack
        className={"search-result fade-in"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          height: "220px",
          cursor: "default !important",
          boxShadow: "none !important",
        }}
      >
        <Box sx={{ maxWidth: "30ch", textAlign: "center" }}>
          <Typography variant={"subtitle1"}>
            {"Sorry, there was a problem loading data from Marmalade"}
          </Typography>
          <Typography
            variant={"subtitle1"}
            sx={{ marginTop: "1rem !important" }}
          >
            {
              "Please try refreshing the page in a moment or feel free to contact "
            }
            <Link href={mailLink}>{"support"}</Link>
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

const SubmissionList = ({
  submissions,
  searchConfig,
  configLoading,
  showNoMatches,
  showError,
  searchText,
  filterClicked,
}) => {
  return (
    <Box mt={1} mb={1}>
      {showNoMatches ? (
        <NoMatches searchText={searchText} />
      ) : showError ? (
        <Error />
      ) : (
        <div>
          {submissions?.map((submission, index) => (
            <SearchCard
              submission={submission}
              searchConfig={searchConfig}
              configLoading={configLoading}
              filterClicked={filterClicked}
              key={index}
              data-testid={`submission-${submission.insured}`}
            />
          ))}
        </div>
      )}
    </Box>
  );
};

const Loader = () => (
  <Stack alignItems={"center"} margin={2}>
    <DefaultLoader
      color={"#fa7b35"}
      type={"TailSpin"}
      size={48}
      style={{ marginTop: "0.5rem" }}
    />
    <Typography className={"pulse"} color={"secondary"} mt={1}>
      {"Loading submissions data"}
    </Typography>
  </Stack>
);

const makeQuery = ({ query, filterMode, dateMode, user }) => {
  const makeFilterPrefix = (mode) => {
    if (mode === FILTER_MODE.EVERYTHING) {
      return "";
    } else if (mode === FILTER_MODE.SAVED_BY_ME) {
      const email = user?.attributes?.email;
      return email ? `savedBy:"${email}"` : "";
    } else if (mode === FILTER_MODE.RENEWING_SOON) {
      return "isRenewingWithin3Mo:true";
    } else {
      return "";
    }
  };

  const makeDatePrefix = (mode) => {
    if (mode === DATE_MODE.EVERYTHING) {
      return "";
    } else if (mode === DATE_MODE.THIS_YEAR) {
      const currentYear = new Date().getFullYear().toString();
      return `inception:${currentYear}`;
    } else if (mode === DATE_MODE.LATEST) {
      return `isLatest:true`;
    } else {
      return "";
    }
  };

  return (
    makeFilterPrefix(filterMode) +
    " " +
    makeDatePrefix(dateMode) +
    " " +
    (query ?? "")
  );
};

const makeSort = (sort) => {
  if (sort === SORT_ORDER.DATE) {
    return "-savedAt";
  } else if (sort === SORT_ORDER.INSURED_NAME) {
    return "insured";
  } else {
    return null;
  }
};

const SearchDialog = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const user = useSelector(userSelectors.selectUser);
  const isUserReadOnly = useSelector(userSelectors.isUserReadOnly);

  const searchBarRef = useRef();
  const [cacheKey, setCacheKey] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState(FILTER_MODE.EVERYTHING);
  const [dateMode, setDateMode] = useState(DATE_MODE.EVERYTHING);
  const [sort, setSort] = useState(SORT_ORDER.DATE);

  const query = useDebouncedValue(searchQuery);

  const {
    data: submissions,
    isLoading: submissionsLoading,
    isError,
  } = submissionsService.useQuerySubmissionsQuery(
    {
      cacheKey,
      query: makeQuery({ query, filterMode: filter, dateMode, user }),
      sort: makeSort(sort),
    },
    { skip: !cacheKey }
  );

  const {
    data: searchConfig,
    isLoading: searchConfigLoading,
  } = staticDataService.useConfigQuery("submission_search_config");

  useEffect(() => {
    searchBarRef.current.focus();
  }, []);

  useEffect(() => {
    if (cacheKey == null) {
      const timestamp = new Date().toISOString();
      setCacheKey(timestamp);
    }
  }, [cacheKey, setCacheKey]);

  const handleNewSubmission = () => {
    dispatch(persistenceActions.newSubmission());
    navigate("/submissions/new");
  };

  const showNoMatches =
    !submissionsLoading &&
    (hasValue(searchQuery) || dateMode !== DATE_MODE.EVERYTHING) &&
    submissions?.length === 0;

  const appendSearch = (text) => {
    text = text.trim();
    if (!searchQuery.toLowerCase().includes(text.toLowerCase())) {
      const newSearchQuery = (searchQuery + " " + text).trim();
      searchBarRef.current.updateSearchText(newSearchQuery);
    } else {
      const newSearchQuery = searchQuery
        .split(text)
        .map((query) => query.trim())
        .join(" ");
      searchBarRef.current.updateSearchText(newSearchQuery);
    }
  };

  const updateDateMode = (mode) => {
    if (mode === DATE_MODE.LATEST) {
      const currentYear = new Date().getFullYear().toString();
      if (searchQuery.includes(currentYear)) {
        const newSearchQuery = searchQuery.replace(currentYear, "").trim();
        searchBarRef.current.updateSearchText(newSearchQuery);
      }
    }
    setDateMode(mode);
  };

  return (
    <div className={"search-dialog"}>
      <div className={"search-form"}>
        <div className={"results"}>
          <SearchBar
            ref={searchBarRef}
            onChangeSearch={(searchText) => setSearchQuery(searchText)}
            activeFilter={filter}
            onChangeFilter={(key) => setFilter(key)}
            activeDateMode={dateMode}
            onChangeDateMode={(mode) => updateDateMode(mode)}
            activeSort={sort}
            onChangeSort={(key) => setSort(key)}
          />
          {submissionsLoading || searchConfigLoading ? (
            <Loader />
          ) : (
            <SubmissionList
              submissions={submissions}
              searchConfig={searchConfig}
              configLoading={false}
              showNoMatches={showNoMatches}
              showError={isError}
              searchText={searchQuery}
              filterClicked={(text) => appendSearch(text)}
            />
          )}
        </div>
      </div>
      {!isUserReadOnly && (
        <Fab
          className={"fade-in-slow"}
          onClick={handleNewSubmission}
          color={"primary"}
          sx={{ position: "fixed", right: "1.5rem", bottom: "1.5rem" }}
          data-testid={"new-submission"}
        >
          <AddIcon sx={{ color: "white", fontSize: "2.5rem" }} />
        </Fab>
      )}
    </div>
  );
};

export default SearchDialog;
