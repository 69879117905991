import CentredLoader from "./CentredLoader";
import * as cdr from "./ClaimsDialog.reducer";
import ClaimsTransformer from "./ClaimsTransformer";
import ColumnMapper from "./ColumnMapper";
import SheetSelector from "./SheetSelector";
import ValueMapper from "./ValueMapper";
import { ValueMapperController } from "./ValueMapper.controller";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Snackbar,
} from "@mui/material";
import FilesFileUpload from "components/FilesFileUpload";
import { tabularMimeTypes } from "fileUtils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetClaimsColumnsQuery } from "services/tablesService";

const ClaimsUpload = ({ uploadStarted, uploadCompleted, aborter }) => {
  const onFileDrop = (id, file) => {
    uploadCompleted(id, file);
  };

  return (
    <FilesFileUpload
      onDrop={onFileDrop}
      updateAsAtDate={null}
      asAtDate={null}
      recommendedAsAtDate={null}
      size={{ md: 4 }}
      waitText={false}
      mimeTypes={tabularMimeTypes}
      uploadInitiated={uploadStarted}
      aborter={aborter}
    />
  );
};

const ClaimsDialog = () => {
  const [mappingConfig, setMappingConfig] = useState(cdr.clearMapping());
  const [dialogOpen, setDialogOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [tablesList, setTablesList] = useState(null);
  const [isConversionComplete, setIsConversionComplete] = useState(false);
  const [valueMapperControllers, setValueMapperControllers] = useState([]);
  const [targetColumnList, setTargetColumnList] = useState([]);
  const [targetColumnMap, setTargetColumnMap] = useState({});
  const [sourceHeaders, setSourceHeaders] = useState([]);
  const [allValuesMapped, setAllValuesMapped] = useState({});
  const [requiredColumns, setRequiredColumns] = useState([]);
  const [tips, setTips] = useState({});

  const {
    data: mappingConfiguration,
    status: mappingConfigurationStatus,
  } = useGetClaimsColumnsQuery();

  useEffect(() => {
    if (mappingConfigurationStatus === "fulfilled") {
      const columns = mappingConfiguration.columns;

      const reqCols = columns
        .filter((e) => e.required)
        .map((col) => col.column);
      setRequiredColumns(reqCols);

      const tipList = columns
        .filter((e) => e.tip)
        .map((e) => [e.column, e.tip]);
      setTips(Object.fromEntries(tipList));

      const enumCols = columns.filter((e) => e.type === "enum");
      const controllers = [];
      for (const columnId in enumCols) {
        const column = enumCols[columnId];
        controllers.push(
          new ValueMapperController({
            key: column.column,
            label: column.display,
            options: Object.entries(column.enumVals).map((ev) => ({
              key: ev[0],
              display: ev[1],
            })),
          })
        );
      }
      setValueMapperControllers(controllers);
    }
  }, [
    setValueMapperControllers,
    mappingConfigurationStatus,
    mappingConfiguration,
  ]);
  const reset = useCallback(() => {
    abortCommand();
    setStep(0);
    setDialogOpen(false);
    setMappingConfig(cdr.clearMapping());
    setTablesList([]);
    setTargetColumnMap({});
    setTargetColumnList([]);
    setSourceHeaders([]);
    setAllValuesMapped({});
    setIsConversionComplete(false);
  }, []);

  const onPrev = () => setStep(Math.max(step - 1, 1));
  const onNext = () => setStep(Math.min(step + 1, steps.length - 1));

  const steps = useMemo(
    () => [
      {
        component: (
          <CentredLoader label={`Uploading ${mappingConfig?.filename}`} />
        ),
      },
      {
        title: `Choose Data`,
        component: (
          <SheetSelector
            fileId={mappingConfig?.fileId}
            tableName={mappingConfig?.tableName}
            origin={mappingConfig?.origin}
            setTableName={(tableName) =>
              setMappingConfig((cd) => cdr.setTableName(cd, { tableName }))
            }
            setOrigin={(origin) =>
              setMappingConfig((cd) => cdr.setOrigin(cd, { origin }))
            }
            setTablesList={setTablesList}
          />
        ),
        nextDisabled: () => !mappingConfig?.tableName,
      },
      {
        title: `Choose Columns`,
        component: (
          <ColumnMapper
            fileId={mappingConfig?.fileId}
            origin={mappingConfig?.origin}
            tableName={mappingConfig?.tableName}
            headerRows={mappingConfig?.headerRows}
            updateColumns={(mappingList) => {
              mappingList.forEach((columnMap) =>
                setMappingConfig((cd) =>
                  cdr.updateColumnMapping(cd, { ...columnMap })
                )
              );
            }}
            targetColumnList={targetColumnList}
            setTargetColumnList={setTargetColumnList}
            targetColumnMap={targetColumnMap}
            setTargetColumnMap={setTargetColumnMap}
            sourceHeaders={sourceHeaders}
            setSourceHeaders={setSourceHeaders}
            requiredColumns={requiredColumns}
            columnTips={tips}
          />
        ),
        nextDisabled: () =>
          !targetColumnList?.length ||
          !sourceHeaders?.length ||
          !mappingConfig?.mapping?.length ||
          !requiredColumns.every((col) =>
            mappingConfig?.mapping?.some(
              (mapping) => mapping.outputName === col
            )
          ),
      },
      {
        title: `Map Values`,
        component: (
          <ValueMapper
            fileId={mappingConfig?.fileId}
            origin={mappingConfig?.origin}
            tableName={mappingConfig?.tableName}
            headerRows={mappingConfig?.headerRows}
            mapping={mappingConfig?.mapping}
            controllers={valueMapperControllers}
            updateValueMapping={(list) => {
              list.forEach((vm) =>
                setMappingConfig((cd) =>
                  cdr.updateConversionMapping(cd, { ...vm })
                )
              );
            }}
            setAllValuesMapped={setAllValuesMapped}
            updateConstantConversion={(outputName, value) => {
              setMappingConfig((cd) =>
                cdr.updateConstantConversionValue(cd, { outputName, value })
              );
            }}
          />
        ),
        nextDisabled: () =>
          mappingConfigurationStatus !== "fulfilled" ||
          !Object.entries(allValuesMapped)
            .filter(([col, _]) =>
              mappingConfig?.mapping?.some(
                (mapping) => mapping.outputName === col
              )
            )
            .every(([_, allMapped]) => allMapped),
      },
      {
        title: `Consistency Checks`,
        component: (
          <ClaimsTransformer
            fileId={mappingConfig?.fileId}
            origin={mappingConfig?.origin}
            tableName={mappingConfig?.tableName}
            headerRows={mappingConfig?.headerRows}
            mapping={mappingConfig?.mapping}
            claimsKey={mappingConfig?.claimsKey}
            filename={mappingConfig?.filename}
            tablesList={tablesList}
            onConversionComplete={() => {
              reset();
              setIsConversionComplete(true);
            }}
          />
        ),
      },
    ],
    [
      mappingConfig,
      targetColumnList,
      targetColumnMap,
      sourceHeaders,
      valueMapperControllers,
      tablesList,
      allValuesMapped,
      mappingConfigurationStatus,
      reset,
      requiredColumns,
      tips,
    ]
  );

  const nextDisabled =
    step >= steps.length - 1
      ? true
      : typeof steps[step].nextDisabled === "function"
      ? steps[step].nextDisabled()
      : false;

  const activeStep = steps[step];
  const aborter = useRef(null);
  const abortCommand = () => {
    aborter.current.abort();
  };
  return (
    <>
      <ClaimsUpload
        uploadCompleted={(fileId, _) => {
          setMappingConfig((cd) => cdr.setFileId(cd, { fileId }));
          setStep(1);
        }}
        uploadStarted={(file) => {
          setMappingConfig((cd) =>
            cdr.setFilename(cd, { filename: file.name })
          );
          setDialogOpen(true);
          setStep(0);
        }}
        aborter={aborter}
      />

      {isConversionComplete && (
        <Snackbar
          open={isConversionComplete}
          autoHideDuration={3500}
          TransitionComponent={Slide}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => setIsConversionComplete(false)}
        >
          <Alert severity={"success"} variant={"filled"}>
            {"Claims data imported"}
          </Alert>
        </Snackbar>
      )}

      {dialogOpen && (
        <Dialog
          open
          fullWidth
          maxWidth={false}
          PaperProps={{ sx: { minHeight: "90vh" } }}
        >
          <DialogTitle>{activeStep.title}</DialogTitle>
          <DialogContent>{activeStep.component}</DialogContent>
          <DialogActions sx={{ minHeight: "3.625rem" }}>
            {
              <>
                <Button
                  onClick={reset}
                  disableElevation
                  variant={"contained"}
                  color={"secondary"}
                >
                  {"Cancel"}
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                {step > 0 && (
                  <>
                    <Button
                      onClick={onPrev}
                      disableElevation
                      variant={"contained"}
                      color={"primary"}
                      disabled={step < 2}
                    >
                      {"Prev"}
                    </Button>
                    <Button
                      onClick={onNext}
                      disableElevation
                      variant={"contained"}
                      color={"primary"}
                      disabled={nextDisabled}
                    >
                      {"Next"}
                    </Button>
                  </>
                )}
              </>
            }
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ClaimsDialog;
