import SearchBar from "./SearchBar";
import SearchCard from "./SearchCard";
import { DATE_MODE, FILTER_MODE, SORT_ORDER } from "./constants";
import * as searchSupport from "./searchSupport";
import { AddRounded as AddIcon } from "@mui/icons-material";
import { Box, Fab, Link, Stack, Typography } from "@mui/material";
import { hasValue } from "common/strings";
import DefaultLoader from "components/common/DefaultLoader";
import OfflineBanner from "components/common/OfflineBanner";
import { SUPPORT_EMAIL } from "config";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as claimsService from "services/claimsService";
import * as staticDataService from "services/staticDataService";
import * as submissionsService from "services/submissionsService";
import * as persistenceActions from "store/actions/persistence/persistenceActions";
import * as userSelectors from "store/selectors/user/userSelectors";

const NoMatches = ({ searchText }) => {
  const label =
    "No matches found" + (searchText === "" ? "" : ` for '${searchText}'`);
  return (
    <Stack
      className={"search-result"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        height: "100px",
        cursor: "default !important",
        boxShadow: "none !important",
      }}
    >
      {label}
    </Stack>
  );
};

const Error = () => {
  const mailLink =
    "mailto:" + SUPPORT_EMAIL + "?subject=Error%20Loading%20Search";

  return (
    <>
      <OfflineBanner />
      <Stack
        className={"search-result fade-in"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          height: "220px",
          cursor: "default !important",
          boxShadow: "none !important",
        }}
      >
        <Box sx={{ maxWidth: "30ch", textAlign: "center" }}>
          <Typography variant={"subtitle1"}>
            {"Sorry, there was a problem loading data from Marmalade"}
          </Typography>
          <Typography
            variant={"subtitle1"}
            sx={{ marginTop: "1rem !important" }}
          >
            {
              "Please try refreshing the page in a moment or feel free to contact "
            }
            <Link href={mailLink}>{"support"}</Link>
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

const SubmissionList = ({
  submissions,
  searchConfig,
  configLoading,
  showNoMatches,
  showError,
  searchText,
  filterClicked,
}) => {
  return (
    <Box mt={1} mb={1}>
      {showNoMatches ? (
        <NoMatches searchText={searchText} />
      ) : showError ? (
        <Error />
      ) : (
        <div>
          {submissions.map((submission, index) => (
            <SearchCard
              submission={submission}
              searchConfig={searchConfig}
              configLoading={configLoading}
              filterClicked={filterClicked}
              key={index}
              data-testid={`submission-${submission.insured}`}
            />
          ))}
        </div>
      )}
    </Box>
  );
};

const Loader = () => (
  <Stack alignItems={"center"} margin={2}>
    <DefaultLoader
      color={"#fa7b35"}
      type={"TailSpin"}
      size={48}
      style={{ marginTop: "0.5rem" }}
    />
    <Typography className={"pulse"} color={"secondary"} mt={1}>
      {"Loading submissions data"}
    </Typography>
  </Stack>
);

const SearchDialog = ({ isUserReadOnly, userEmail, newSubmission }) => {
  const { data: industryClasses } = claimsService.useIndustryClassesQuery();
  const {
    data: businessUnits,
    isLoading: businessUnitsLoading,
  } = staticDataService.useConfigQuery("business_units");
  const {
    data: reinsurers,
    isLoading: reinsurersLoading,
  } = staticDataService.useConfigQuery("reinsurers");
  const configLoading = businessUnitsLoading || reinsurersLoading;

  const navigate = useNavigate();

  const searchBarRef = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState(FILTER_MODE.EVERYTHING);
  const [dateMode, setDateMode] = useState(DATE_MODE.EVERYTHING);
  const [sort, setSort] = useState(SORT_ORDER.DATE);

  const {
    data: submissions,
    isLoading: submissionsLoading,
    isError,
    refetch: refetchSubmissions,
  } = submissionsService.useListSubmissionsQuery();

  const {
    data: searchConfig,
    isLoading: searchConfigLoading,
  } = staticDataService.useConfigQuery("submission_search_config");

  const augmentedSubmissions = searchSupport.augmentSubmissions(
    submissions,
    industryClasses,
    businessUnits?.active,
    reinsurers?.reinsurers
  );

  const filteredSubmissions = searchSupport.searchSubmissions(
    augmentedSubmissions,
    {
      searchQuery,
      filter,
      dateMode,
      sort,
      userEmail,
      limit: 100,
    }
  );

  useEffect(() => {
    searchBarRef.current.focus();
  }, []);

  useEffect(() => {
    refetchSubmissions();
  }, [refetchSubmissions]);

  const handleNewSubmission = () => {
    newSubmission();
    navigate("/submissions/new");
  };

  const showNoMatches =
    !submissionsLoading &&
    (hasValue(searchQuery) || dateMode !== DATE_MODE.EVERYTHING) &&
    filteredSubmissions?.length === 0;

  const appendSearch = (text) => {
    text = text.trim();
    if (!searchQuery.toLowerCase().includes(text.toLowerCase())) {
      const newSearchQuery = (searchQuery + " " + text).trim();
      searchBarRef.current.updateSearchText(newSearchQuery);
    } else {
      const newSearchQuery = searchQuery
        .split(text)
        .map((query) => query.trim())
        .join(" ");
      searchBarRef.current.updateSearchText(newSearchQuery);
    }
  };

  const updateDateMode = (mode) => {
    if (mode === DATE_MODE.LATEST) {
      const currentYear = new Date().getFullYear().toString();
      if (searchQuery.includes(currentYear)) {
        const newSearchQuery = searchQuery.replace(currentYear, "").trim();
        searchBarRef.current.updateSearchText(newSearchQuery);
      }
    }
    setDateMode(mode);
  };

  return (
    <div className={"search-dialog"}>
      <div className={"search-form"}>
        <div className={"results"}>
          <SearchBar
            ref={searchBarRef}
            onChangeSearch={(searchText) => setSearchQuery(searchText)}
            activeFilter={filter}
            onChangeFilter={(key) => setFilter(key)}
            activeDateMode={dateMode}
            onChangeDateMode={(mode) => updateDateMode(mode)}
            activeSort={sort}
            onChangeSort={(key) => setSort(key)}
          />
          {submissionsLoading || searchConfigLoading ? (
            <Loader />
          ) : (
            <SubmissionList
              submissions={filteredSubmissions}
              searchConfig={searchConfig}
              configLoading={configLoading}
              showNoMatches={showNoMatches}
              showError={isError}
              searchText={searchQuery}
              filterClicked={(text) => appendSearch(text)}
            />
          )}
        </div>
      </div>
      {!isUserReadOnly && (
        <Fab
          className={"fade-in-slow"}
          onClick={handleNewSubmission}
          color={"primary"}
          sx={{ position: "fixed", right: "1.5rem", bottom: "1.5rem" }}
          data-testid={"new-submission"}
        >
          <AddIcon sx={{ color: "white", fontSize: "2.5rem" }} />
        </Fab>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  newSubmission: persistenceActions.newSubmission,
};

const mapStateToProps = (state) => ({
  isUserReadOnly: userSelectors.isUserReadOnly(state),
  userEmail: userSelectors.selectUserAttributes(state)?.email ?? null,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchDialog);
